.preloader {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  display: -webkit- flex;
  display: -ms- flex;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  background: #ffffff;
  position: fixed;
  z-index: 999999;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.preloader__logo {
  position: absolute;
}
.preloader__logo img {
  width: 55px;
}
.preloader__circle-loader {
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 5px;
  border-top-color: var(--optional-color);
  border-right-color: var(--optional-color);
  border-bottom-color: #d5eaff;
  border-left-color: #d5eaff;
  border-radius: 50%;
  -webkit-animation: spinLoader 2s linear infinite;
  animation: spinLoader 2s linear infinite;
}
.preloader.preloader-deactivate {
  visibility: hidden;
}

@-webkit-keyframes spinLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}